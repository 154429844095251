import { Component, computed, input, output, signal } from '@angular/core';
import { SharedModule } from '../../../../../../Shared/shared.module';
import {
  ECMSContentRefCat,
  ECMSModuleType,
  ICMSContent,
  ICMSReference,
  ICMSResource,
} from '../../../cms.model';

import SaveCMSResourceComponent from '../../save-cms-resource/save-cms-resource.component';

@Component({
  selector: 'cms-explorer-submapper',
  imports: [SharedModule, SaveCMSResourceComponent],
  templateUrl: './cms-explorer-submapper.component.html',
  styleUrl: './cms-explorer-submapper.component.scss',
})
export class CmsExplorerSubmapperComponent {
  readonly blocks = input<ICMSContent[]>([]);
  readonly applicationCode = input<string>();
  readonly baseQuery = input<Partial<ICMSReference>>();
  readonly saved = output();
  
  protected readonly formedResource = computed(() => {
    const [parent, ...subHelpGuideContent] = this.blocks();
    const ret: ICMSResource = {
      ...parent,
      subHelpGuideContent,
    };

    return ret;
  });

  readonly baseReference = computed<ICMSReference>(() => ({
    applicationCode: this.applicationCode(),
  ...this.baseQuery()
  }));
}
