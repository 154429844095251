import {
  ChangeDetectionStrategy,
  Component,
  computed,
  linkedSignal,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { EUserHelperID, IUserHelperConfig, IUserHelperModule } from './user-helper.model';
import { CommonModule } from '@angular/common';
import { UserHelperService } from './user-helper.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, OnDestroy, inject } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UserHelpHelpComponent } from './user-help-help/user-help-help.component';
import { UtilityService } from '../../../../Services/utility.service';
import { UserHelpNotesComponent } from './user-help-notes/user-help-notes.component';
import { UserHelpChatComponent } from './user-help-chat/user-help-chat.component';
import { UserHelpTaskComponent } from './user-help-task/user-help-task.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { debounceTime, ReplaySubject } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { AppRouteService } from 'ets-fe-ng-sdk';
import { MessageService } from '../../../../Reusables/reusable-pages/messages/message.service';
import { MatBadgeModule } from '@angular/material/badge';
import { DirectivesModule, PSDirective } from '../../../directives/index.directive';
import { SharedModule } from '../../../shared.module';

@Component({
  selector: 'user-helper',
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    CdkDrag,
    CdkDragHandle,
    MatListModule,
    MatBadgeModule,
    SharedModule,
    UserHelpHelpComponent,
    UserHelpChatComponent,
    UserHelpNotesComponent,
    UserHelpTaskComponent,
    PSDirective,
  ],
  templateUrl: './user-helper.component.html',
  styleUrl: './user-helper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UserHelperComponent {
  readonly modules = computed(() => {
    const totalPendingCounts = this.totalPendingCounts();
    return this.service
      .modules()
      .map((x) => ({ ...x, badge: x.id == EUserHelperID.notification ? totalPendingCounts : null }));
  });
  readonly show = computed(() => this.service.show());
  readonly showEntireModule = computed(() => this.service.showEntireModule());
  // readonly selectedModule = linkedSignal<IUserHelperModule | null>(() => this.modules().slice(-2)[0]);
  readonly selectedModule = linkedSignal<IUserHelperModule | null>(() => null);
  readonly EUserHelperID = EUserHelperID;

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  readonly service = inject(UserHelperService);
  readonly changeDetectorRef = inject(ChangeDetectorRef);
  readonly media = inject(MediaMatcher);

  readonly containerClassObj = computed(() => ({ opened: !!this.selectedModule() }));
  readonly bgColor = computed(() => {
    this.appRoute.currentRouteSignal();
    // debugger;
    return this.uS.getPrimaryColorTrans(0.8);
  });

  readonly dragging$ = new ReplaySubject(1);
  readonly dragging = toSignal(this.dragging$.pipe(debounceTime(100)));

  readonly totalPendingCounts = computed<number>(
    () => this.messageService.unreadNotificationsCount() || null,
  );

  constructor(
    public uS: UtilityService,
    public appRoute: AppRouteService,
    public messageService: MessageService,
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  selectModule(item: IUserHelperModule) {
    if (item.action) {
      this.service.toggle();
      item.action();
    } else this.selectedModule.set(item);
  }

  draggingEnd() {
    this.dragging$.next(false);
  }
}
