@if (content().meta?.displayFileContent) {
  @if (content()._hidePreview) {
    <div class="d-flex gap-2 align-items-center preview-text">
      <h5 class="m-0">{{ content().title| appTranslateNL | async }}</h5>
      {{ 'Preview is hidden' | appTranslateNL | async }}
      <div (click)="updateContent({ _hidePreview: false })" class="text-primary pointer">
        {{ 'Show Preview' | appTranslateNL | async }}
      </div>
    </div>
  } @else {
    <loader [loading]="loading()">
      <pdf-viewer
        [src]="pdfSrc()"
        [render-text]="false"
        [original-size]="false"
        style="width: 100%; height: 80vh" />
    </loader>
  }
} @else {
  <app-btn
    text="Download {{ content().title }}"
    icon="download"
    (mclick)="service.donwloadFile(content(), dBtn)"
    #dBtn />
}
