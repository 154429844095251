<loader [loading]="contentResource.isLoading()">
  @if (contentResource.value(); as contentResource) {
    <cms-resource [content]="contentResource" [hideOutline]="hideOutline()" [sideBarTemplate]="allowEdit() ? sideBarTempl : null" />
    <ng-template #sideBarTempl>
      @if (allowEdit()) {
        <a
          routerLink="../edit"
          mat-flat-button
          class="rounded-10"
          color="primary"
          queryParamsHandling="merge">
          <i class="fas fa-pen"></i>
          {{ 'Edit' | appTranslateNL | async }}
        </a>
      }
    </ng-template>
  } @else {
    <div class="p-3">
      {{ 'Content could not found' | appTranslateNL | async }}
    </div>
  }
</loader>
