import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  Input,
  input,
  output,
  signal,
  TemplateRef,
} from '@angular/core';
import { CMSResourceItemComponent } from './cms-resource-item/cms-resource-item.component';
import { CMSResourceService } from './cms-resource.service';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { SharedModule } from 'ets-fe-ng-sdk';
import SaveCmsContentComponent from '../../cms-content-bank/save-cms-content/save-cms-content.component';
import { ICMSContent, ICMSResource } from '../../cms.model';
import { MatTreeNestedDataSource, MatTreeModule } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { CMSLayoutService } from '../../cms-layout/cms-layout.service';

import { outputFromObservable, toSignal } from '@angular/core/rxjs-interop';
import { debounceTime, from, fromEvent, ReplaySubject, tap } from 'rxjs';

@Component({
  selector: 'cms-resource',
  standalone: true,
  imports: [
    SharedModule,
    CMSResourceItemComponent,
    MatTreeModule,
    MatIconModule,
    MatListModule
],
  templateUrl: './cms-resource.component.html',
  styleUrl: './cms-resource.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CMSResourceService],
})
export class CMSResourceComponent {
  readonly service = inject(CMSResourceService);
  @Input('allowEdit')
  set setAllowEdit(v: boolean) {
    this.service.allowEdit.set(v);
  }
  readonly content = input<ICMSResource>();
  readonly allowSubRemapping = input<boolean>();
  readonly sideBarTemplate = input<TemplateRef<any>>();
  readonly hideOutline = input<boolean>();

  readonly openEdit = outputFromObservable(this.service.editItem$);
  readonly openSelectExistingItem = outputFromObservable(this.service.selectExistingItem$);
  readonly openAddItem = outputFromObservable(this.service.addItem$);
  readonly removeItem = outputFromObservable(this.service.removeItem$);
  readonly selectedContentBlockList = output<ICMSContent[]>();
  readonly parent = document.querySelector<HTMLElement>('.cms-layout mat-sidenav-content');
  readonly id = 'cmsResource_' + performance.now().toFixed(0);
  private readonly scrolled = signal(null);
  readonly contentTree = computed(() => {
    const _children = this.content()?.subHelpGuideContent || [],
      children: ICMSResource[] = [],
      noEdit = !this.service.allowEdit(),
      scrolled = this.scrolled(),
      hideOutline = this.hideOutline();
    // debugger;
    if (_children?.length)
      for (let index = 0; index < _children.length; index++) {
        const element = _children[index];
        if ((noEdit && element.meta?.hideTitle) || !element.title) continue;
        children.push({
          ...element,
          _inView: this.service.isScrolledIntoView(
            `#${this.id} #${this.service.resourceItemIDPrefix + element.id}`,
          ),
        });
      }
    return children;
  });
  protected hideSideBar = computed<boolean>(() => !this.contentTree()?.length && !this.sideBarTemplate());

  public layoutService = inject(CMSLayoutService);
  constructor() {
    if (this.parent)
      this.parent.onscroll = (e) => {
        this.scrolled.set(performance.now());
      };

    effect(() => {
      this.service.allowSubRemapping.set(this.allowSubRemapping());
    });
    effect(() => {
      this.selectedContentBlockList.emit(this.service.selectedContentBlockList());
    });
    // debugger;
  }
  scrollIntoView(node: ICMSResource) {
    document
      .getElementById(this.service.resourceItemIDPrefix + node.id)
      ?.scrollIntoView({ behavior: 'smooth' });
  }
}
