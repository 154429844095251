import { CommonModule } from '@angular/common';
import { Component, model } from '@angular/core';
import { CMSContentTypeComponent } from '../cms-content-type.base.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ICMSAccordion } from '../../../cms.model';
import { SharedModule } from '../../../../../../Shared/shared.module';

@Component({
  selector: 'render-cms-accordion',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, SharedModule],
  templateUrl: './render-cms-accordion.component.html',
  styleUrl: './render-cms-accordion.component.scss',
})
export class RenderCMSAccordionComponent extends CMSContentTypeComponent {
  readonly content = model.required<ICMSAccordion>();
}
