import { computed, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ApiService } from '@Services/api.service';
import { IApplication, IApplicationSearchResult } from '../shared/models/admin-application.interface';
import { IBaseFormSchema } from 'ets-fe-ng-sdk/lib/Shared/components/form-generator/form-generator.base.component';
import { IGetQuery } from 'ets-fe-ng-sdk';
import { map, merge } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class AdminApplicationService {
  baseUrl = environment.apiBaseUrl + '/admin/rest/application/';
  readonly formSchema = computed<Omit<IBaseFormSchema<any, any>, 'field' | 'children'>>(() => ({
    label: 'Application',
    type: 'autocomplete',
    options: this.allApplications(),
    valueField: 'code',
    labelField: ['code', 'applicationName'],
  }));
  readonly allApplications = toSignal(this.getAllApplications(), { rejectErrors: true });
  readonly allApplicationsIDMap = computed(() => this.allApplications()?.toMap('id') || {});
  constructor(public apiService: ApiService) {}

  /**
   *
   * @param id Id of application
   * @returns Observable of Application details
   */
  getApplicationById(id: string) {
    return this.apiService.get<IApplication>(this.baseUrl + id);
  }

  /**
   *
   * @param id Id of application
   * @param data Data to be updated
   * @returns Observable of update process
   */
  updateApplicationById(id: string, data: any) {
    return this.apiService.put<IApplication>(this.baseUrl + id, data);
  }

  /**
   *
   * @param id Id of application
   * @returns Details of application deleted
   */
  deleteApplicationById(id: string) {
    return this.apiService.delete<IApplication>(this.baseUrl + id);
  }

  /**
   *
   * @param data Data to be created
   * @returns Observable of Created Application
   */
  createApplication(data: any) {
    return this.apiService.post<IApplication>(this.baseUrl, data);
  }

  /**
   *
   * @param query Query to use to identify applications
   * @returns Array of applications created
   */
  searchApplication(query: IGetQuery<{ applicationName: string }>) {
    return this.apiService.get<IApplicationSearchResult>(this.baseUrl + 'search/', query);
  }
  /**
   *
   * @param name Name to query by
   * @returns Array of applications created
   */
  getAllApplications() {
    return this.searchApplication({ pageNumber: 1, pageSize: 50 }).pipe(map((r) => r.content));
  }
}
