import { Component, input } from '@angular/core';
import { SharedModule } from '../../../../../Shared/shared.module';
import { ICMSResourceQuery } from '../../cms.model';
import { inject } from '@angular/core';
import { CMSService } from '../../cms.service';
import { catchError, of, switchMap } from 'rxjs';

import { PageTemplateComponent } from '../../../../../Shared/components/page-template/page-template.component';
import { CMSResourceComponent } from '../cms-resource/cms-resource.component';
import { CMSLayoutService } from '../../cms-layout/cms-layout.service';
import { CMSResourceService } from '../cms-resource/cms-resource.service';
import { rxResource, toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'view-cms-resource',
  standalone: true,
  imports: [SharedModule, CMSResourceComponent],
  providers: [CMSResourceService],
  templateUrl: './view-cms-resource.component.html',
  styleUrl: './view-cms-resource.component.scss',
})
export default class ViewCMSResourceComponent extends PageTemplateComponent {
  readonly service = inject(CMSService);
  readonly cmsLayoutService = inject(CMSLayoutService);

  readonly allowEdit = input<boolean>(true);
  readonly hideOutline = input<boolean>();
  readonly query = input<ICMSResourceQuery>();

  readonly routeQuery = toSignal<ICMSResourceQuery>(this.route.queryParams);
  readonly contentResource = rxResource({
    request: () => (this.query() ? this.query() : this.routeQuery()),
    loader: ({ request }) =>
      this.service.getContentResource(request).pipe(
        switchMap((r) =>
          r || !request.helpGuideContentId ? of(r) : this.service.getContentById(request.helpGuideContentId),
        ),
        catchError((e) => {
          this.service.uS.info('Content could not be loaded', 0);
          throw e;
        }),
      ),
  });

  async ngOnInit(): Promise<void> {
    this.cmsLayoutService.setTitle(`View Help Resource`);
  }

  ngOnDestroy(): void {
    this.cmsLayoutService.setTitle(null);
  }
}
