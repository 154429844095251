<loader [loading]="loading() && !content()._hidePreview" class="vh-10">
  @if (content(); as _content) {
    @if (_content._hidePreview) {
      <div class="d-flex gap-2 preview-text">
        {{ 'Preview is hidden' | appTranslateNL | async }}
        <div (click)="updateContent({ _hidePreview: false })" class="text-primary pointer">
          {{ 'Show Preview' | appTranslateNL | async }}
        </div>
      </div>
    } @else {
      <img [src]="image()" alt="{{ altText() | appTranslateNL | async }}" />
    }
    @if (_content.title) {
      <div class="image-caption" [innerHTML]="_content.title | appTranslateNL | async"></div>
    }
  }
</loader>
